.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.header-title-container {
  margin-top: 40px;
}

.header-title {
  font-size: 128px;
  letter-spacing: -9.15px;
  font-weight: 500;
  line-height: 122px;
}

.header-blue {
  font-size: 196px;
  display: inline-block;
  position: relative;
  color: #00acff;
  height: 220px;
}

.header-blue::after {
  content: " ";
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 60px;
  background: #bbf2ff;
  bottom: 35px;
  z-index: -1;
}

.header-title-container img {
  position: absolute;
  top: 0;
  width: 400px;
}

/* https://www.w3schools.com/howto/howto_css_shake_image.asp */
.header-title-container img {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 10s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, 1px) rotate(0deg);
  }
}

.pools-table {
  border: 1px solid #eee;
  border-collapse: collapse;
  margin: 20px auto;
}

.pools-table tr {
  border-bottom: 1px solid #eee;
}

.pools-table th {
  padding: 20px 30px;
}

.pools-table td {
  padding: 20px 20px;
}

.pools-table .name {
  display: flex;
  flex-direction: column;
}

.pools-table .icon {
  width: 40px;
}

.pools-table .title {
  font-weight: 600;
}

.pools-table .title .text {
  font-weight: 600;
  font-size: 1em;
  margin-left: 10px;
}

.pools-table .title a {
  text-decoration: none;
  color: #204e1f;
  font-weight: 600;
}

.filters-container {
  width: 400px;
  display: flex;
  flex-direction: column;
  margin: -20px auto 50px auto;
}

.filter-toggle {
  margin: 10px;
  display: flex;
  justify-content: "center";
  align-items: "center";
}

.filter-toggle .name {
  margin-left: 12px;
}

/* HELPER */

.center-text {
  text-align: center;
  vertical-align: middle;
}
.right-align-text {
  text-align: right;
  vertical-align: middle;
}

.marker-highlight {
  background-color: #bbf2ff;
}
